import React from "react"
import { graphql } from "gatsby"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./ContactUs.module.css"
import HubspotForm from "components/HubspotForm"

// eslint-disable-next-line
export const ContactUs = ({ data, headerImage, pinIcon, letterIcon, ...props }) => (
  <Layout {...props} {...data}>
    <Container className={styles.root}>
      <div className={styles.infoContainer}>
        <Title>Contact Us</Title>
        {data.description ? (
          <p>{data.description}</p>
        ) : null}
        <div className={styles.info}>
          <div className={styles.locations}>
            <h3>Our Locations</h3>
            <p>{data.locs || ""}</p>
          </div>
          <div className={styles.email}>
            <h3>Email</h3>
            <p>
              <a href={`mailto:${data.email}`}>{data.email || ""}</a>
            </p>
          </div>
        </div>
        <div className={styles.image}>
          <Img
            loading="lazy"
            width="320"
            height="320"
            objectFit="contain"
            alt="Contact Us"
            title="Contact Us"
            fluid={headerImage.childImageSharp.fluid}
          />
        </div>
      </div>
      <div className={styles.formContainer}>
        <HubspotForm id={data.form} portalId={data.portalId} />
      </div>
    </Container>
  </Layout>
)

// eslint-disable-next-line
export const ContactUsWrapper = ({ data, ...props }) => (
  <ContactUs
    {...props}
    headerImage={data.headerImage}
    pinIcon={data.pinIcon}
    letterIcon={data.letterIcon}
    data={data.markdownRemark.frontmatter}
  />
)

export default ContactUsWrapper

export const pageQuery = graphql`
  query ContactUsQuery {
    headerImage: file(relativePath: { eq: "contact-us-image.png" }) {
      extension
      publicURL
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    pinIcon: file(relativePath: { eq: "icon-purple-pin.svg" }) {
      extension
      publicURL
    }
    letterIcon: file(relativePath: { eq: "icon-yellow-letter.svg" }) {
      extension
      publicURL
    }
    markdownRemark(frontmatter: { templateKey: { eq: "contactus" } }) {
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            extension
            publicURL
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        description
        locs
        email
        form
        portalId
      }
    }
  }
`
